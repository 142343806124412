import React from 'react';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';
import portret_alicja_sq from '../images/alicja_sq.png';
import portret_aneta_sq from '../images/aneta_sq.png';
import portret_masha_sq from '../images/masha_sq.png';
import portret_monika_sq from '../images/monika_sq.png';
import portret_paulina_sq from '../images/paulina_sq.png';
import portret_milosz_sq from '../images/milosz_sq.png';
import portret_anna_sq from '../images/anna_sq.png';
import portret_magda_sq from '../images/magda_sq.png';
import portret_daria_sq from '../images/daria_sq.png';

const EnglishPage = () => {
    return (
        <Layout>
            <Seo title="english" />
            <section className='main-intro'>

                <StaticImage 
                    src='../images/landing_en.png' 
                    placeholder="blurred"
                    loading="eager"
                    alt="instruktorka trenująca pilates w studio" 
                    className='main-intro_picture'
                    transformOptions={{ fit: "cover" }}
                />
                <h2 className='main-intro_statement darken-statement'>You are the architect of your body</h2>

                <div className='main-link'>
                    <a href="#about" className='main-link_link'>READ MORE</a>
                </div>
            </section>
            
            <section className="about_english" id='about'>
                <h2 className="english_title">About our Studio</h2>
                <p className="english_subtext">We are a professional Therapeutic Pilates* Studio. We follow the modern approach, starting work with the client from comprehensive diagnostics, matching exercises and their modifications to the capabilities, needs and goals of the client.
                <br /><br />Our Studio is equipped with machines: Reformer with Tower, Cadillac, Wunda Chair, Combo Chair, Ladder Barrel, Spine Corrector and Clinical Reformer, which is the only Pilates equipment adapted to work with children and adolescents.
                <br /><br />We conduct classes stationary and on-line in many languages : Polish, English, French, Spanish, Ukrainian, Russian and Japanese.
                <br /><br />* The Pilates method is one of the most extensive systems of movement exercises that allows you to optimize the functions of individual muscle groups.
                <br /><br />The goal of Pilates is to return to the correct body posture, restore the natural strength and proper function of all muscles, and then improve the functionality of the joints and restore their full range of motion without pain.
                </p>
            </section>

            <section>
                <section className='english_picture-container'>
                    <div className='english_picture-container_half'>
                        <StaticImage 
                            src='../images/one_en.png'
                            alt="duża sala do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                    <div className='english_picture-container_half'>
                        <StaticImage 
                            src='../images/two_en.png'
                            alt="sprzęt treningowy do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                </section>
                <section className='english_picture-container'>
                    <div className='english_picture-container_half'>
                        <StaticImage 
                            src='../images/three_en.png'
                            alt="duża sala do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                    <div className='english_picture-container_half'>
                        <StaticImage 
                            src='../images/four_en.png'
                            alt="sprzęt treningowy do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                </section>
            </section>
            
            <section className="offer_english" id="offer">
                <h2 className="english_title">Our Offer</h2>
                <div className='offer_english-suboffer'>
                    <h3 className="english_paragraph-name">Individual Classes</h3>
                    <p className="english_subtext">We conduct individual Pilates classes on machines (including: Clinical Reformer with Tower, Cadillac, Ladder Barrel, Wunda Chair, Combo Chair, Spine Corrector) and on mat with small equipment, such as: Balls, Rollers, Magic Circle, Bands, Air disc, etc.
                    <br /><br />At the beginning of each Pilates therapeutic cycle, an individual diagnostic consultation is carried out. The purpose of the consultation is to find out your readiness for physical activity, evaluate the work of key muscle groups and set goals that you can achieve thanks to the Pilates method.
                    <br /><br />Each training session is therefore 100% tailored to individual needs.</p>
                    <div className='signup-hero_link-container_return english_fix'>
                        <a className='signup-hero_link-link_return' href='#signup'>Learn More</a>
                    </div>
                    <div className='signup-hero_link-container_return english_fix'>
                        <a className='signup-hero_link-link_return' href='https://app.fitssey.com/pilatesterapeutyczny/frontoffice' target='_blank'>Sign up</a>
                    </div>
                </div>
                <div className='offer_english-suboffer'>
                    <h3 className="english_paragraph-name">Duo Classes</h3>
                    <p className="english_subtext">We conduct duo Pilates classes for two participants on machines (including: Clinical Reformer with Tower, Cadillac, Ladder Barrel, Wunda Chair, Combo Chair, Spine Corrector) and on mat with small equipment, such as: Balls, Rollers, Magic Circle, Bands, Air disc, etc.
                    <br /><br />At the beginning of each Pilates therapeutic cycle, every participant has to go through an individual diagnostic consultation. The purpose of the consultation is to find out your readiness for physical activity, evaluate the work of key muscle groups and set goals that you can achieve thanks to the Pilates method.
                    <br /><br />In duo classes you get come universal exercises, but modified to your needs. We work on improving body posture, strengthening deep muscles, sculpting the figure, restoring flexibility, as well as optimizing the work of the respiratory system as well as the sense of balance and proprioception.
                    <br /><br />During our duo classes, we combine an individualized approach with mutual motivation and a nice atmosphere of training.
                    </p>
                    <div className='signup-hero_link-container_return english_fix'>
                        <a className='signup-hero_link-link_return' href='https://app.fitssey.com/pilatesterapeutyczny/frontoffice' target='_blank'>Sign up</a>
                    </div>
                </div>
            </section>

            <section className='about-showcase_picture padding-top'>
                <div className='about-showcase_picture_half'>
                    <StaticImage 
                        src='../images/five_en.png'
                        alt="duża sala do zajęć pilates"
                        className="about-showcase_picture_half-half"
                    />
                </div>
                <div className='about-showcase_picture_half'>
                    <StaticImage 
                        src='../images/six_en.png'
                        alt="sprzęt treningowy do zajęć pilates"
                        className="about-showcase_picture_half-half"
                    />
                </div>
            </section>

            <section className='team-container margin-bottom' id="team">
                <section className='team-intro english-team'>
                    <h1 className='team-intro_title'>Meet our team</h1>
                </section>
                <section className='team-hero margin-bottom'>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_alicja_sq} alt="Instruktorka Alicja Rukowicz" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Alicja Rukowicz</h3>
                                <p className='team-hero_card_information-description'> Owner of Therapeutic Pilates Studio, certified Therapeutic Pilates instructor, certified trainer of many methods of working with the body, linguist, marketing manager, educator and master teacher
                                <br /><br />Polish, French, English
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_paulina_sq} alt="Instruktorka Paulina Benitez" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Paulina Benitez</h3>
                                <p className='team-hero_card_information-description'>Certified Pilates instructor, dance and Stretching instructor, a linguist.
                                <br /><br /><br /><br />Polish, Spanish, English, Japanese, French
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_monika_sq} alt="Instruktorka Monika Adamiec" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Monika Adamiec</h3>
                                <p className='team-hero_card_information-description'>Certified Pilates instructor, sociologist, special educator.
                                <br /><br />Polish, English
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_milosz_sq} alt="Instruktor Miłosz Kaźmierczak" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Miłosz Kaźmierczak</h3>
                                <p className='team-hero_card_information-description'>Certified Pilates instructor, biologist.
                                <br /><br />Polish, English
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_aneta_sq} alt="Instruktorka Aneta Kosmowska" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Aneta Kosmowska</h3>
                                <p className='team-hero_card_information-description'>Certified Pilates instructor, ballet artist.
                                <br /><br />Polish, French
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_anna_sq} alt="Instruktorka Anna Rostek" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Anna Rostek</h3>
                                <p className='team-hero_card_information-description'>Certified Pilates instructor
                                <br /><br />Polish, English
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_masha_sq} alt="Instruktorka Masha Zhuk" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Masha Zhuk</h3>
                                <p className='team-hero_card_information-description'>Certified Pilates instructor, for many years the first soloist of the Polish National Ballet.
                                <br /><br />Polish, English, Russian
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_magda_sq} alt="Instruktorka Magda Sobieska" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Magda Sobieska</h3>
                                <p className='team-hero_card_information-description'>Certified Pilates instructor
                                <br /><br />Polish
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='team-hero_card english-card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_daria_sq} alt="Instruktorka Daria Kyiko" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Daria Kyiko</h3>
                                <p className='team-hero_card_information-description'>Pilates instructor
                                <br /><br />Polish, English, Ukrainian
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className='first-time_english' id="signup">
                <h2 className="english_title">How to attend our classes</h2>
                <h3 className="english_subtext margin-bottom">We accept registration and payments for classes only through the Fitssey booking system (internet platform and/or mobile application).</h3>
                <ul className="english_list">
                    <li>1.&nbsp; First, in a few seconds, you will set up your profile using the link below, enter your basic data in the registration form and this way you will create your profile in our Studio.</li>
                    <li>2.&nbsp; After registration, you can buy the classes you would like to attend (i.e. select the appropriate service from the price list: e.g. "Diagnostic consultation with body posture analysis" or "Pilates class – one entry » or you can chose one of our packages.</li>
                    <li>3.&nbsp; If you have chosen an individual approach, please contact us to determine your needs, the trainer's specialization and the date of the diagnostic session. Then you will be able to book a visit inserted for you in the schedule. Before the first meeting, do not forget to send us the completed diagnostic questionnaire by e-mail.</li>
                    <li>4.&nbsp; By signing up through the system, everyone is able to manage their profile. We accept registration at least 24 hours before classes. We also make cancellations and postponements at least 24 hours before the class. Otherwise, the system blocks the recording for that moment. We thank you in advance for your understanding.</li>
                </ul>
                <p className="english_subtext">If you need further clarifications, please contact us!</p>
                <div className='english_download-docs english_fix'>
                    <a href='/documents/Regulamin.pdf' download="Rules and Regulations_Pilates Terapeutyczny Studio PL/ENG" className='english_download-document'>DOWNLOAD OUR STATUTE &#8628;</a>
                    <a href='/documents/Diagnostic_questionnaire.docx' download="Questionnaire" className='english_download-document'>DOWNLOAD QUESTIONNAIRE &#8628;</a>
                </div>
                <div className='signup-hero_link-container_return english_fix'>
                    <a className='signup-hero_link-link_return' href='https://app.fitssey.com/pilatesterapeutyczny/frontoffice' target='_blank'>Sign up</a>
                </div>
            </section>

            <section className='about-showcase_picture padding-top'>
                <div className='about-showcase_picture_half'>
                    <StaticImage 
                        src='../images/seven_en.png'
                        alt="duża sala do zajęć pilates"
                        className="about-showcase_picture_half-half"
                    />
                </div>
                <div className='about-showcase_picture_half'>
                    <StaticImage 
                        src='../images/eight_en.png'
                        alt="sprzęt treningowy do zajęć pilates"
                        className="about-showcase_picture_half-half"
                    />
                </div>
            </section>

            <section className='faq margin-top' id='faq'>
                <h2 className="english_title">FAQ</h2>
                <div>
                    <h3 className="english_question">What can Pilates classes do for you? After how long do you see the effects?</h3>
                    <p className="english_subtext">Pilates is a method of working with the body in which we fix patterns in the movement system. We work on awareness and correct body posture, movement control and restoring balance and strength of individual muscle groups. Thanks to this, we prevent injuries and lead away from possible muscle dysfunctions and pain.
                    <br /><br />This is not an ordinary set of exercises, but a method - that is, we work with great precision and focus on the appropriate activation of the nervous, respiratory and motor systems.
                    <br /><br />On average, it takes about 6 sessions for the body to really start changing patterns in the movement system, so we only need a few weeks to see the first visible effects and feel the desired sensations in the body.
                    </p>
                </div>
                <div>
                    <h3 className="english_question">Is Pilates safe for everyone? Can anyone do Pilates?</h3>
                    <p className="english_subtext">The Pilates method is the safest form of working with the body that has been developed so far. It is based on the knowledge of anatomy and neurology.
                    <br /><br />In the mode of individual classes, when the instructor devotes all his attention to your case, practically every person can work out what needs to be fixed in the body in the most effective way.
                    <br /><br />We work with various people from the „vulnerable groups”, including people with posturale defects, people after accidents, injuries or surgeries, pregnant women or just after childbirth, women preparing to become pregnant, elderly, oncological or blind people, people with locomotor dysfunctions resulting from congenital defects, genetic diseases whether autoimmune or acquired diseases. But also with athletes or professional dancers who need to minimize potential overloads on a daily basis and take care of the functionality and muscle balance in their body.
                    <br /><br />Pilates is also often used by healthy people as simply an interesting, engaging physical activity.</p>
                </div>
                <div>
                    <h3 className="english_question">Is Pilates difficult and can you get tired or sweaty doing Pilates?</h3>
                    <p className="english_subtext">At the beginning, we focus on the basics and simple patterns of movement, building the ability to engage various systems or arranging joint rhythm. Over time, the exercises become multidimensional, we engage different parts of the body at the same time, we increase the pace of work as well as loads and resistances, possibly to the level of almost acrobatic work, if people want to strive for the level of perfection in controlling their body.
                    <br /><br />In Pilates, all exercises are performed in correlation with breathing and we take care of proper oxygenation, so despite the relative effort, you should not feel overextended. However, because we have to completely reorganize the body's current habits and due to the nature of this method, e.g. Activating "lazy" muscles and calming overactive muscles, exercise may seem difficult and you may get tired of it.
                    </p>
                </div>
                <div>
                    <h3 className="english_question">Is Pilates a kind of yoga?</h3>
                    <p className="english_subtext">Pilates is not the same as yoga. It is a more modern method of working with the body, based on the science of the „Western culture” (i.e. on modern discoveries in the field of anatomy, including biomechanics or breath physiology)
                    <br /><br />However, similarly to yoga, Pilates belongs to the "body and mind" classes, i.e. in training we engage the body and mind at the same time and practically as intensively. This means that we consciously work to manage our body through precise mind commands, focusing on breath and balance.</p>
                </div>
                <div>
                    <h3 className="english_question">What is the Reformer and how is it practiced?</h3>
                    <p className="english_subtext">The Reformer is the most universal Pilates machine, it allows you to work in many body positions, planes and axes of movement, using your own body weight but also strings and additional resistances, as well as working on an unstable surface.
                    <br /><br />The very beginnings of the Pilates method were developed during the First World War as part of the rehabilitation of soldiers and prisoners of war in field hospitals. Already then, Joseph Pilates, the creator of this method, was constructing machines from elements available on site (cots, springs, ropes, bars, chairs, barrels, etc.). Modern Pilates machines, including the Reformer, are very similar to the original ones, because they reflect similar functionalities.</p>
                </div>
                <div>
                    <h3 className="english_question">How to prepare for Pilates classes?</h3>
                    <p className="english_subtext">During training, we exercise in a comfortable outfit that does not restrict movement. We practice without shoes (either barefoot or in socks, depending on the need for more stability or greater slide).
                    <br /><br />It is also worth making sure that you have an exercise mat with you, just in case, if a part of the classes was carried out on the floor, e.g. to get used to some movement before we perform it on the machine. But we also have universal mats that you can use and that we disinfect on a regular basis. However, it is good to have your own Pilates mat anyway, because we hope that you will find it useful at home, so that you can perform some very beneficial exercises more often than just in the studio, so that the effect of your work with the body is as good as possible.
                    <br /><br />Meanwhile, in order to start individual training with us, the first meeting is a diagnostic consultation and to prepare for it, we need to exchange a diagnostic questionnaire in which you answer questions related to your health history, injuries, lifestyle and goals for working with the body . You can also additionally bring the results of imaging tests of the musculoskeletal system if you have them (X-rays or descriptions only, descriptions of magnetic resonance imaging or ultrasound), but this is not required.
                    <br /><br />In the very questionnaire that you will receive from us at the beginning of our cooperation, you will find all the tips on how to prepare thoroughly for the diagnostic session and Pilates classes.
                    </p>
                </div>
            </section>
            <section className='contact_english' id="contact">
                <h2 className="english_title english_fix">Contact</h2>
                <h3 className="english_question">If you have any questions, feel free to contact us here</h3>
                <div className='contact-information_adress-details english-sm-fix'>
                    <a href="mailto:pilates.terapeutyczny@gmail.com">pilates.terapeutyczny@gmail.com</a>
                    <a href="https://www.facebook.com/pilatesterapeutyczny/" target="_blank">FACEBOOK</a>
                    <a href="https://www.instagram.com/pilatesterapeutyczny/" target="_blank">INSTAGRAM</a>
                </div>
                <div className='contact-information_adress-details english-sm-fix'>
                    <p>71 Wilcza Street APT. 4, Warsaw<br/>Second Floor, building with an elevator</p>
                    <p>Tel: +48 502 274 535 </p>
                </div>
                <div className='english_map-link-container'>
                    <a className="english_map-link" href="https://www.google.com/maps?ll=52.22324,21.0087&z=16&t=m&hl=en&gl=PL&mapclient=embed&cid=2580676489993665930" target="_blank">You can find us on google maps here</a>
                </div>
            </section>
        </Layout>
    )
}

export default EnglishPage;